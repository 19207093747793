.dropdown300{
  max-height:300px;
  overflow:auto;
  border:black;
  border-radius: 1px;
  background-color:beige;
  display: none;
}
.dropdown-item:hover{
  background-color: gray;
  cursor: pointer;
}