.section-title {
  background: #555555;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.section {
  background: #e5e5e2;
  margin-top: 15px;
  padding: 15px;
  padding-top: 5px;
  border-radius: 30px;
}

.section-body {
  overflow: hidden;
  display: block;
  transition: max-height 0.2s ease-out;
}

.section-title:after {
  color: white;
  float: right;
  margin-left: 5px;
}

.active,
.section-title:hover {
  background-color: #888;
}
.font-green {
  color: green;
}
.font-red {
  color: red;
}

.formsPayment {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.titlePayment{
  font-size: 20px;
  font-weight: bold;
  color: #317415;
}

.divbutton{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 40%;
}

.credit-card-input {
  font-size: 16px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 45px;
}


.separator {
  padding: 0 10px;
  font-size: 35px;
  line-height: 50px; 
  color: #333; 
}

.credit-card-input-expiration{
  font-size: 16px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50px;
  height: 45px;
}

.divExpiration{
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  background-color: #ffebee;
  border: 1px solid #f44336;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  color: #b71c1c;
}

.error-message h3 {
  margin-top: 0;
  color: #d32f2f;
}