.containerSchedule{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modal-content h3 {
  margin-top: 0;
}

.modal-content input[type="text"] {
  width: calc(100% - 24px);
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.divbuttonsModal{
    display: flex;
    justify-content: space-evenly;
    
}

.modal-content button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #45a049;
}

.modal-content button:last-child {
  background-color: #f44336;
}
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.th,
.td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.th {
  background-color: #801819;
}

.tr:hover {
  background-color: #f5f5f5;
}

.searchContainer {
  position: relative;
  margin-bottom: 20px;
}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.searchInput {
  width: 100%;
  padding: 10px 10px 10px 40px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.searchInput:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 2px 8px rgba(74, 144, 226, 0.2);
}

.searchInput::placeholder {
  color: #aaa;
}

.select-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 10px 35px 10px 10px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  width: 100%;
  max-width: 200px; 
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.custom-select:hover {
  border-color: #999999;
}

.custom-select:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
}

.custom-select option {
  padding: 10px;
}



.success-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1000;
  animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.periodFilterContainer {
  position: relative;
  width: 250px;
}

.periodSelect {
  appearance: none;
  width: 100%;
  padding: 10px 35px 10px 15px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.periodSelect:hover {
  border-color: #888;
}

.periodSelect:focus {
  border-color: #801819;
  box-shadow: 0 0 0 2px rgba(128, 24, 25, 0.2);
}

.periodFilterContainer::after {
  content: '▼';
  font-size: 12px;
  color: #801819;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.periodSelect option {
  padding: 10px;
}