
details {
  padding: 5px;
  cursor: pointer;
}

summary:hover {
  background-color: rgba(245, 245, 220, 0.781);
}
summary {
  color: rgb(90, 88, 88);
}
.nameTurma {
  cursor: pointer;
  color: #801819;
  text-decoration: none;
  margin-left: 15px;
}

.colorheaderEMF {
  background: rgb(209, 229, 214);
  background: linear-gradient(
    90deg,
    rgba(209, 229, 214, 1) 35%,
    rgba(189, 177, 228, 1) 92%
  );
}

.divIMG{
  display: flex;
  justify-content: center;
  padding: 5px
}


.logoEMF{
  width: 170px;
  height: 60px;
}

.logoCeconte{
  width: 195px;
  height: 62px;
}

.colorheaderCeconte {
  background: #801819;
  color:white;
  height: 80px;
}

.colorheaderCeconte .logout {
  color:white;
  cursor: pointer;
}

.turma-button{
  font-size: 12px;
  cursor: pointer;
  color: #801819;
}

.colorIcon{
  color: #330506;
}

.selectTurma{
  font-weight:bold;
  padding: 20px;
  color:#801819
}