.dashboard-card {
  padding:5px;
  border-radius: 10px;
  border-style: outset;
  border-width: 5px;
  border-color: white;
  width: 300px;
}
.dashboard{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
}
.dashboard-value{
  font-family: monospace;
  font-size: larger;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background:rgb(211, 240, 205);
}
.dashboard-text{
  min-height: 150px;
}