.dialog-content{
    display: flex;
    flex-direction: column;
    height:auto;
    padding:10px;
}
.option-lesson{
 margin-bottom:5px;
}


.btn-assign-presence{
 border-radius:5px;
 border: none;
 background: #71d375;
 cursor: pointer;
}

