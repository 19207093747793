.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.p30{
  padding: 30px;
}
.p5{
  padding: 5px;
}
.m10{
  margin: 10px;
}
.center{
  text-align: center;
}
.align-left{
  text-align: left;
}
.align-right{
  text-align: right;
}
.flex-center{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.update-bar{
  background-color: red;
  text-align: center;
  font-weight: bold;
}

.flex-col{
  display: flex;
  flex-direction: column;
}

.flex-row{
  display: flex;
  flex-direction: row;
}