.container-csv{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid #ccc;
    padding: 20px;
}

.form-csv{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin-top: 20px;
}

.form-csv label{
    font-weight: bold;
    margin-right: 10px;
}

.form-csv button {
    max-width: 200px;
    margin-bottom: 50px;
}
.form-csv input {
    margin-left: 10px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.table-csv{
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
}

.th-csv {
    background-color: #ccc;
      padding: 1rem;
      text-align: center;
      color: #000;
      font-size: 0.875rem;
      line-height: 1.6;

      &:first-child {
        border-top-left-radius: 8px;
        padding-left: 1.5rem;
      }
      &:last-child {
        border-top-right-radius: 8px;
        padding-right: 1.5rem;
      }
}

.td-csv{
    border-top: 4px solid #ccc;
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.6;
    &:first-child {
      width: 50%;
      padding-left: 1.5rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
.tbody-csv{
   border: 1px solid #ccc;
}

.td-falta-csv{
  color: red;
  border-top: 4px solid #ccc;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  &:first-child {
    width: 50%;
    padding-left: 1.5rem;
  }
  &:last-child {
    padding-right: 1.5rem;
  }
}

.td-csv-presenca{
  border-top: 4px solid #ccc;
  color: green;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  &:first-child {
    width: 50%;
    padding-left: 1.5rem;
  }
  &:last-child {
    padding-right: 1.5rem;
  }
}