.credit_card_number{
  font-size: large;
  margin: 5px;
}
.credit_card_cvv{
  font-size: large;
  margin: 5px;
}

.credit_card_name{
  font-size: large;
  margin: 5px;
}

.credit_card_expiration{
  font-size: large;
  margin: 5px;
}