.conteiner {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.containerButtons {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 20px;
}

.linkAdmins{
    text-decoration: none;
    background: #801819;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #801819;
}

.linkAdmins:hover{
    background: #fff;
    color: #801819;
    transition: 0.5s;
    border: 1px solid #801819;
}
