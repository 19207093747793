.container-fundamentos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.image-fundamentos {
  width: 70%;
  margin-bottom: 20px;
}
.price {
  font-size: 20px;
  font-weight: bold;
  color: #801819;
}


.descriptionFFAP {
  font-size: 17px;
  text-align: center;
  padding: 10px;
}

.image-fundamentos-responsive{
  display: none;
}

@media (max-width:750px) {
  .descriptionFFAP{
    font-size: 12px;
  }
}

@media (max-width:700px) {
  .image-fundamentos{
    display: none;
  }
  .image-fundamentos-responsive{
    display: block;
    width: 400px;
    margin-bottom: 20px;
  }
}