.imgWorkshop2{
    width: 80%;
    margin-bottom: 20px;
}


.descriptionWorkshop{
    font-size: 18px;
}

.profWorkshop{
    font-size: 22px;
    font-weight: bold;
}

.alertWorkshop{
    padding: 20px;
    font-size: 16px;

}

.imgWorkshop2Responsivo{
    display: none;
}

.b-alert{
    color: rgb(213, 0, 0);
}


@media (max-width:750px) {
    .descriptionWorkshop {
        font-size: 15px;
    }
    .alertWorkshop{
        font-size: 12px;
    }
}
 @media (max-width:700px) {
  .imgWorkshop2 {
    display: none;
  }
  .imgWorkshop2Responsivo{
    display: block;
    width: 400px;
    margin-bottom: 20px;
  }
}