.link-grade-studant {
  padding: 9px;
  text-decoration: none;
  cursor: pointer;
  background-color: #339c9c;
  color: #fff;
  font-weight: bold;
  border: 0.5px solid #339c9c;
  border-radius: 5px;
  font-size: 14px;;
}

.link-grade-studant:hover {
  background-color: #fff;
  color: #801819;
  transition: 0.5s;
  border: 0.5px solid #801819;
}

.container-table {
  width: 80%;
}

.loading {
  font-size: 16px;
  font-weight: bold;
  color: #801819;
}
