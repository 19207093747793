.containerAdmin {
  padding: 20px;
}



.studentName{
  font-size: 18px;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.tableAdmin {
  width: 100%;
  border-collapse: collapse;
}

.thadmin,
.tdAdmin,
.status-aprovado-admin,
.status-pendente-admin,
.status-reprovado-admin {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.tdAdmin button {
  padding: 5px 10px;
  margin-right: 10px;
  background-color: #fbb24d;
  border: none;
  color: black;
  border-radius: 5px;
}

.tdAdmin button:hover {
  background-color: #fbb24d;
  cursor: pointer;
}

.thadmin {
  background-color: #801819;
  color: white;
  font-weight: bold;
}

.modal {
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  z-index: 100;
  border: 2px solid #801819;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
}

.modal h3 {
  color: #333;
  margin-bottom: 20px;
}

.modal p {
  margin-bottom: 20px;
  color: #666;
}

.modal-contentAdmin {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  margin-top: 30px;
}


.b-aprovar{
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.b-aprovar:hover {
  background-color: #00ff00;
}

.b-reprovar{
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.b-reprovar:hover {
  background-color: #801819;
}
.modal .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  background-color: #f8f9fa;
}

.modal label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.modal input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.status-aprovado-admin span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  margin-right: 5px;
}

.status-pendente-admin span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #b77c00;
  margin-right: 5px;
}

.status-reprovado-admin span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
}

.docAviso{
  background-color: #fbb24d;
  padding: 5px 15px;
  border-radius: 10px;
}