.bnt-login {
  background: #edd75a;
  border: none;
  border-radius: 14px;
  width: 17.438rem;
  height: 2.938rem;
  left: 59.875rem;
  top: 43.438rem;
  cursor: pointer;
  margin-top: 3.125rem;
  font-weight: 600;
  font-size: 16px;
}

.bnt-login:hover {
  background-color: #ffef93;
}

.form-login {
  margin: 0 auto;
  margin-top: 6.25rem;
  width: 19.375rem;
  height: 25.75rem;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-radius: 26px;
  padding: 0.625rem;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.div-form-login {
  display: contents;
}

.div-login {
  display: flex;
}

.bnt-debug {
  border: none;
  background-color: #fafafa;
  cursor: pointer;
  font-size: 12px;
}

.text-login {
  width: 21.563rem;
  height: 33.804rem;
  background: #f2e8e8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 124px 131px 0px 0px;
  transform: rotate(90deg);
  display: flex;
  align-items: center;
}

.book-login {
  margin-bottom: 1.25rem;
  width: 200px;
}


.login-text {
  transform: rotate(270deg);
}

.user-login {
  width: 6.25rem;
  margin-bottom: 0.625rem;
}

@media screen and (max-width: 1159px) {
  .text-login {
    width: 275px;
    height: 495.86px;
    margin-right: 50px;
  }
  .login-text {
    font-size: 12px;
    line-height: 1.50;
  }
  .form-login{
    margin-top: 4.25rem;
    width: 18.375rem;
    height: 28.75rem;
  }
}

@media screen and (max-width:1048px){
    .text-login{
        box-shadow:none;
        transform:none;
        width: auto;
        height: auto;
        border-radius: 20px;
        margin-right: 0;
    }
    .login-text{
        transform:none;
        padding: 10px;
    }
    .div-login{
        display: block
    }

}

@media screen and (max-width:845px){
    .div-form-login{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .form-login{
        margin-top: 0;
        margin:30px;
        width :17.375rem;
         height: 25.75rem;
}
    .bnt-login{
       font-size: 14px;
       width: 12.437999999999999rem;
       height: 1.9380000000000002rem;
    }
    .user-login{
        width: 5.25rem;
        margin-bottom: 0.625rem;
    }

}

@media screen and (max-width:560px){
    .div-login{
        margin-top: 20px;
    }
}