.container {
  padding: 20px;
}

.titleAttachments {
  text-align: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.th, .td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}
.th{
  color: white;
  background-color: #801819;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
.linkAnexos{
  color: #007bff;
  text-decoration: none;
  border: 1px solid #007bff;
  padding: 5px;
  border-radius: 5px;
  width: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.linkAnexos:hover {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}


.descricao{
  font-size: 16px;
  font-weight: semi-bold;
}

.fileInputContainer {
  margin-bottom: 10px; 
}

.fileInput {
  display: block;
  width: 100%;
  margin: 0 auto 10px auto;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.enviarButtonStudent {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.enviarButtonStudent:hover {
  background-color: #0056b3;
}

.status-aprovado span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  margin-right: 5px;
}

.status-pendente span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #b77c00;
  margin-right: 5px;
}

.status-reprovado span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
}

.warning{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  gap: 10px;
  margin-top: 20px;
}