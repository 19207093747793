.week4 {
    background-color: #f5e237;
    margin-bottom: 2px;
}
.week4:hover{
    background-color: #c0b129;
}

.week6 {
    background-color: #f28806;
    margin-bottom: 2px
}
.week6:hover{
    background-color: #c07a05;
}

.week8{
    background-color: #f74040e4;
    margin-bottom: 2px
}

.week8:hover{
    background-color: #c02f2f;
}

.default{
    background-color: #dfdfdfd3;
}

.default:hover{
    background-color: #bababa;
}

.captionColors{
    display: flex;
    align-items: center;
    margin-top: 10px
}

.yellowColor{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f5e237;
    color:#ffffff
}

.orangeColor{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f28806;  
}

.redColor{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f74040e4;
    color:#ffffff
}

.provasEMF{
    display:none;
}

@media screen and (max-width: 630px) {
   .yellowColor {
    width: 20px;
    height: 15px;
    border-radius: 100%;
    margin-right: 10px;
   }
   .orangeColor {
    width: 20px;
    height: 15px;
    border-radius: 100%;
    margin-right: 10px;
   }
    .redColor {
     width: 20px;
     height: 15px;
     border-radius: 100%;
     margin-right: 10px;
    }
}